import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { toWidget , viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'
import DynamicParamCommand from './dynamicparamcommand'

export default class DynamicParamEditing extends Plugin {
  static get requires() {
      return [ Widget ];
  }

  init() {
      console.log( 'DynamicParamEditing#init() got called' );

      this._defineSchema();
      this._defineConverters();

      this.editor.commands.add( 'dynamicParam', new DynamicParamCommand( this.editor ) );

      this.editor.editing.mapper.on(
          'viewToModelPosition',
          viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( 'dynamicParam' ) )
      );
      this.editor.config.define( 'dynamicParamConfig', {
          types: [ 'date', 'first name', 'surname' ]
      } );
  }

  _defineSchema() {
      const schema = this.editor.model.schema;

      schema.register( 'dynamicParam', {
          // Allow wherever text is allowed:
          allowWhere: '$text',

          // The placeholder will act as an inline node:
          isInline: true,

          // The inline widget is self-contained so it cannot be split by the caret and it can be selected:
          isObject: true,

          // The inline widget can have the same attributes as text (for example linkHref, bold).
          allowAttributesOf: '$text',

          // The dynamicParam can have many types, like date, name, surname, etc:
          allowAttributes: [ 'name' ]
      } );
  }

  _defineConverters() {
      const conversion = this.editor.conversion;

      conversion.for( 'upcast' ).elementToElement( {
          view: {
              name: 'span',
              classes: [ 'dynamicParam' ]
          },
          model: ( viewElement, { writer: modelWriter } ) => {
              // Extract the "name" from "{name}".
              const name = viewElement.getChild( 0 ).data.slice( 1, -1 );

              return modelWriter.createElement( 'dynamicParam', { name } );
          }
      } );

      conversion.for( 'editingDowncast' ).elementToElement( {
          model: 'dynamicParam',
          view: ( modelItem, { writer: viewWriter } ) => {
              const widgetElement = createDynamicParamView( modelItem, viewWriter );

              // Enable widget handling on a dynamicParam element inside the editing view.
              return toWidget( widgetElement, viewWriter );
          }
      } );

      conversion.for( 'dataDowncast' ).elementToElement( {
          model: 'dynamicParam',
          view: ( modelItem, { writer: viewWriter } ) => createDynamicParamView( modelItem, viewWriter )
      } );

      // Helper method for both downcast converters.
      function createDynamicParamView( modelItem, viewWriter ) {
          const name = modelItem.getAttribute( 'name' );

          const dynamicParamView = viewWriter.createContainerElement( 'span', {
              class: 'dynamicParam'
          } );

          // Insert the dynamicParam name (as a text).
          const innerText = viewWriter.createText( '{' + name + '}' );
          viewWriter.insert( viewWriter.createPositionAt( dynamicParamView, 0 ), innerText );

          return dynamicParamView;
      }
  }
}