import Command from '@ckeditor/ckeditor5-core/src/command'

export default class DynamicParamCommand extends Command {
  execute( { value, customAttributes } ) {
      const editor = this.editor;
      const selection = editor.model.document.selection;
      customAttributes = customAttributes || {};

      editor.model.change( writer => {
          // Create a <placeholder> elment with the "name" attribute (and all the selection attributes)...
          const dynamicParam = writer.createElement( 'dynamicParam', {
              ...Object.fromEntries( selection.getAttributes() ),
              name: value,
              ...customAttributes
          } );

          // ... and insert it into the document.
          editor.model.insertContent( dynamicParam );

          // Put the selection on the inserted element.
          writer.setSelection( dynamicParam, 'on' );
      } );
  }

  refresh() {
      const model = this.editor.model;
      const selection = model.document.selection;

      const isAllowed = model.schema.checkChild( selection.focus.parent, 'dynamicParam' );

      this.isEnabled = isAllowed;
  }
}